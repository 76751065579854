import { createI18n } from 'vue-i18n';

import en from '../../assets/locales/en.json';
import fr from '../../assets/locales/fr.json';
import collections from '../../assets/badges/collections.json';

let concatFr = { ...fr, collections: {} };
let concatEn = { ...en, collections: {} };

for (var collection of collections) {
  concatFr.collections[collection.id] = { name: collection.name.fr };
  concatEn.collections[collection.id] = { name: collection.name.en };
}

// Create i18n instance with options
const i18n = createI18n({
  legacy: false,
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: {
    en: concatEn,
    fr: concatFr,
  },
});

export default i18n;
