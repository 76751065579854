export default {
  mounted() {
    // The base URLs for replacement
    const oldBaseUrl = 'https://myntfsid.mypinata.cloud/ipfs';
    const newBaseUrl = process.env.VUE_APP_MYDID_IPFS_BALANCER_URL;

    // Select all img elements in the document
    const imgs = document.querySelectorAll('img');

    // Update the src for all found img elements that match the condition
    imgs.forEach((img) => {
      if (img.src.startsWith(oldBaseUrl)) {
        img.src = img.src.replace(oldBaseUrl, newBaseUrl);
      }
    });
  },
};
