<template>
  <div class="">
    <Vue3Lottie
      :animationLink="require('/assets/images/loader-plus.jpg')"
      :height="150"
      :width="150"
      :speed="1"
      :loop="false"
    />
    <p class="text-center font-bold mt-10">
      {{
        `${$t('templates.firstCreateTxt')} ${templateLabel} ${$t(
          'templates.firstCreateTxtBis'
        )}`
      }}
    </p>
    <p class="text-center">
      {{ $t('templates.firstCreateTxtTer') }}
    </p>
    <div class="flex justify-center">
      <button
        @click="triggerCreate"
        class="text-lg bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-4 mt-8"
      >
        {{ $t('templates.createButton') }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useI18n } from 'vue-i18n';
export default {
  props: {
    type: String,
  },
  setup(props, context) {
    const utils = inject('utils');

    const templateLabel = ref(utils.templateTypeToLabel(props.type, useI18n()));

    function triggerCreate() {
      context.emit('create');
    }

    return { templateLabel, triggerCreate };
  },
};
</script>
