<template>
  <div></div>
</template>

<script>
import { ref, inject, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import '@sjmc11/tourguidejs/src/scss/tour.scss';
import { TourGuideClient } from '@sjmc11/tourguidejs';

export default {
  setup(props, context) {
    const { t } = useI18n();
    let tg = null;

    function start(steps) {
      if (tg) return;

      let order = 0;
      let computedSteps = [];

      for (const step of steps) {
        if (document.getElementById(step.id))
          computedSteps.push({
            content: step.content,
            title: step.title,
            target: '#' + step.id,
            ordier: order++,
          });
      }

      tg = new TourGuideClient({
        showStepDots: false,
        // showStepProgress: false,
        nextLabel: t('tourGuide.nextButton'),
        prevLabel: t('tourGuide.previousButton'),
        finishLabel: t('tourGuide.finishButton'),
        dialogMaxWidth: 500,
        steps: computedSteps,
      });

      function tourFinished() {
        const backdropDivs = document.querySelectorAll('div.tg-backdrop');
        const dialogDivs = document.querySelectorAll('div.tg-dialog');

        const removeElements = (elements) => {
          elements.forEach((element) => {
            element.parentNode.removeChild(element);
          });
        };

        removeElements(backdropDivs);
        removeElements(dialogDivs);

        context.emit('finish');
      }

      tg.onFinish(() => {
        tourFinished();
        tg = null;
      });
      tg.onBeforeExit(() => {
        tourFinished();
        tg = null;
      });

      tg.start();
    }

    return { start };
  },
};
</script>
