import { ref } from 'vue';
const eventBus = ref(new Map());

export default function eventsBus() {
  function emit(event, ...args) {
    eventBus.value.set(event, args);
  }

  return {
    emit,
    eventBus,
  };
}
