<template>
  <div class="flex">
    <div class="relative">
      <CookieConsent></CookieConsent>
    </div>
    <div
      v-if="areVerticalBarsDisplayed"
      :key="currentIssuerDid"
      class="flex-none w-80 min-h-screen filter relative overflow-auto"
    >
      <div class="absolute w-full h-full">
        <nav-bar></nav-bar>
      </div>
    </div>
    <div
      class="flex-1 min-h-screen"
      :class="{
        'pt-5': areVerticalBarsDisplayed,
      }"
      :key="currentIssuerDid"
    >
      <div
        class="relative w-full h-full overflow-auto bg-white"
        :class="{
          'rounded-tl-3xl shadow-md border-t border-l':
            areVerticalBarsDisplayed,
        }"
      >
        <div class="absolute w-full h-full">
          <router-view :key="route.path" v-slot="slotProps">
            <component :is="slotProps.Component"></component>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, watch, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import jwt from 'jsonwebtoken';
import CookieConsent from './components/CookieConsent.vue';
import EventsBus from './plugins/eventBus';

export default {
  setup() {
    const utils = inject('utils');
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const cookies = inject('cookies');
    const { locale, t } = useI18n();
    const { eventBus } = EventsBus();

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const currentAddress = computed(() => store.getters.getCurrentAddress);
    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );
    const areVerticalBarsDisplayed = computed(
      () => store.getters.areVerticalBarsDisplayed
    );
    const membershipTemplateList = computed(
      () => store.getters.getMembershipTemplateList
    );
    const templateListLoading = computed(
      () => store.getters.getTemplateListLoading
    );

    onMounted(() => {
      const loader = document.getElementById('app-loader');
      if (loader) {
        loader.style.display = 'none'; // Hide the loader as soon as the app is mounted
      }
    });

    function recoverSession() {
      const token = cookies.get('token');

      if (token) {
        // restore global session
        store.commit('setCurrentIssuerDid', jwt.decode(token).did);
        store.commit(
          'setCurrentAddress',
          utils.didToAddress(jwt.decode(token).did)
        );
        store.dispatch('getTemplateLists');
        store.dispatch('getIssuerInfo');
        return;
      }

      store.commit('resetIssuerState');
    }

    const savedLang = localStorage.getItem('lang');
    if (savedLang) {
      locale.value = savedLang;
    }

    // redirect when issuer changes
    watch(currentIssuerDid, () => {
      if (!currentIssuerDid.value) {
        const forceSignup = cookies.get('signup');
        if (forceSignup) {
          cookies.remove('signup');
          router.replace('/signin?new=1');
        } else router.push('/signin');
        return;
      }
    });

    // do this after watch listener to ensure issuer info are recovered
    recoverSession();

    // onboarding
    watch([templateListLoading, route], () => {
      if (
        currentIssuerDid.value &&
        !templateListLoading.value &&
        membershipTemplateList.value &&
        membershipTemplateList.value.length == 0
      ) {
        if (!route.path.startsWith('/templates/membership'))
          router.push('/templates/membership');
      }
    });

    return {
      currentIssuerDid,
      currentAddress,
      areVerticalBarsDisplayed,
      route,
    };
  },
  components: {
    CookieConsent,
  },
};
</script>
