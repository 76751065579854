<template>
  <label
    class="toggle__button"
    :class="modelValue ? 'active' : ''"
    @click.prevent="toggle"
  >
    <input type="checkbox" :checked="internalValue" />
    <span :class="['toggle__switch', { active: internalValue }]"></span>
  </label>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const internalValue = ref(props.modelValue);

    watch(
      () => props.modelValue,
      (newVal) => {
        internalValue.value = newVal;
      }
    );

    function toggle() {
      internalValue.value = !internalValue.value;
      context.emit('update:modelValue', internalValue.value);
    }

    return { internalValue, toggle };
  },
};
</script>

<style scoped>
.toggle__button {
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}
.toggle__button input[type='checkbox'] {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
}
.toggle__button .toggle__switch {
  display: inline-block;
  height: 15px;
  border-radius: 10px;
  width: 28px;
  background: #eeeff1;
  box-shadow: inset 0 0 1px #bfcbd9;
  position: relative;
  margin-left: 0px;
  transition: all 0.25s;
}

.toggle__button .toggle__switch::after,
.toggle__button .toggle__switch::before {
  content: '';
  position: absolute;
  display: block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  left: 0;
  top: 0px;
  transform: translateX(0);
  transition: all 0.25s cubic-bezier(0.5, -0.6, 0.5, 1.6);
}

.toggle__button .toggle__switch::after {
  background: #ffffff;
  box-shadow: 0 0 1px #666;
}
.toggle__button .toggle__switch::before {
  background: #ffffff;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  opacity: 0;
}

.active .toggle__switch {
  background: #adedcb;
  box-shadow: inset 0 0 1px #adedcb;
}

/* .active .toggle__switch::after,
.active .toggle__switch::before {
  transform: translateX(30px - 15px);
} */

.active .toggle__switch::after {
  left: 13px;
  background: #ffffff;
  box-shadow: 0 0 1px #53b883;
}
</style>
