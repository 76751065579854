<template>
  <div class="py-10 px-16">
    <p class="font-bold text-xl mb-10">
      {{ $t('settings.manageLanguage.title') }}
    </p>
    <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3">{{
      $t('settings.manageLanguage.languagePlaceholder')
    }}</span>
    <select
      required
      rows="1"
      class="w-64 outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5 cursor-pointer"
      v-model="lang"
    >
      <option disabled selected value="">
        {{ $t('settings.manageLanguage.languagePlaceholderSelector') }}
      </option>
      <option value="en">English</option>
      <option value="fr">Français</option>
    </select>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
export default {
  setup() {
    const lang = ref('en');
    const { locale } = useI18n();

    // const { t } = useI18n();
    // console.log(t('settings.language.title'));

    watch(lang, (newLang) => {
      locale.value = newLang;
      localStorage.setItem('lang', newLang);
    });

    onMounted(() => {
      const savedLang = localStorage.getItem('lang');
      if (savedLang) {
        lang.value = savedLang;
        locale.value = savedLang;
      }
    });

    return { lang };
  },
};
</script>
