<template>
  <div
    class="flex items-center border rounded-xl px-1.5 py-0.5 gap-2 cursor-pointer"
    :style="`background-color:${backgroundColor}; border-color:${textColor}`"
  >
    <div class="bg-gray-400 rounded-full w-4 h-4"></div>
    <p class="flex-none text-sm font-medium text-gray-500" :style="`color:${textColor}`">
      {{ label }}
    </p>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  props: {
    label: String,
    type: String,
  },
  setup(props, context) {
    const backgroundColor = ref(null);
    const borderColor = ref(null);
    const textColor = ref(null);

    switch (props.type) {
      case 'Basic':
        textColor.value = '#17936D';
        borderColor.value = '#ABF5DE';
        backgroundColor.value = '#E7FCF5';
        break;
      case 'Community':
        textColor.value = '#4930BB';
        borderColor.value = '#FFE092';
        backgroundColor.value = '#FFECCF';
        borderColor.value = '#A4ADF4';
        backgroundColor.value = '#E5E8FF';
        break;
      case 'Participation':
        textColor.value = '#DBA61C';
        borderColor.value = '#FFE092';
        backgroundColor.value = '#FFECCF';
        break;
      case 'Membership':
        textColor.value = '#3E3F47';
        borderColor.value = '#6D6E7A';
        backgroundColor.value = '#C0C1CB';
        break;
      case 'Role':
        textColor.value = '#6D6E7A';
        borderColor.value = '#C0C1CB';
        backgroundColor.value = '#F0F0F0';
        break;
      default:
        break;
    }

    return { textColor, borderColor, backgroundColor };
  },
};
</script>
