<template>
  <div
    class="flex hover:bg-white items-center rounded-lg px-3 py-2 gap-3 cursor-pointer"
    @mouseenter="showInsideButton = true"
    @mouseleave="showInsideButton = false"
    @click="clickNavButton"
  >
    <div
      v-if="pinColor"
      class="flex-none w-5 h-5 rounded-full"
      :style="`background: radial-gradient(circle at center, white 0%, ${pinColor} 70%)`"
    ></div>
    <div v-else class="flex-none w-5 h-5 relative">
      <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <slot></slot>
      </div>
    </div>
    <p class="flex-none font-medium">
      {{ label }}
    </p>
    <div class="w-full"></div>
    <p
      v-if="showInsideButton && showSendButton"
      @click.stop="clickSendButton"
      class="text-gray-300"
    >
      <icon name="paper-plane" class="fill-gray-300" />
    </p>
    <p
      v-if="showInsideButton && showAddButton"
      @click.stop="clickAddButton"
      class="text-gray-300"
    >
      <icon name="plus" class="fill-gray-300" />
    </p>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  props: {
    label: String,
    pinColor: String,
    showAddButton: Boolean,
    showSendButton: Boolean,
  },
  setup(props, context) {
    const showInsideButton = ref(false);

    function clickNavButton() {
      context.emit('clickNavButton');
    }

    function clickSendButton() {
      context.emit('clickSendButton');
    }

    function clickAddButton() {
      context.emit('clickAddButton');
    }

    return {
      showInsideButton,
      clickNavButton,
      clickSendButton,
      clickAddButton,
    };
  },
};
</script>
