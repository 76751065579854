const clickOutSide = {
  mounted(el, binding, vnode) {
    let startedInside = false;

    el.mouseDownEvent = function (event) {
      startedInside = el === event.target || el.contains(event.target);
    };

    el.mouseUpEvent = function (event) {
      if (
        startedInside &&
        !(el === event.target || el.contains(event.target))
      ) {
        // Ignoring this because the click started inside the element
        return;
      }

      // check ignore attribute on clicked element and its parents
      let element = event.target;
      while (element) {
        const ignoreAttribute = element
          .getAttributeNames()
          .find((name) => name.indexOf('click-outside-ignore') != -1);
        if (
          el
            .getAttributeNames()
            .find((name) => name.indexOf(ignoreAttribute) != -1)
        ) {
          return;
        }
        element = element.parentElement;
      }

      if (!(el === event.target || el.contains(event.target))) {
        // console.log('click outside detected for element :', el);
        binding.value(event, el);
      }
    };

    document.body.addEventListener('mousedown', el.mouseDownEvent);
    document.body.addEventListener('mouseup', el.mouseUpEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('mousedown', el.mouseDownEvent);
    document.body.removeEventListener('mouseup', el.mouseUpEvent);
  },
};

export default clickOutSide;
