import Web3 from 'web3';
import crypto from 'crypto';
import createKeccakHash from 'keccak';
import { toChecksumAddress } from 'ethereum-checksum-address';
import { base58btc } from 'multiformats/bases/base58';
import secp256k1 from 'secp256k1';
import { createToast } from 'mosha-vue-toastify';

const web3 = new Web3(process.env.VUE_APP_WEB3_PROVIDER);

export function asciiToHex(asciiString) {
  return web3.utils.asciiToHex(asciiString);
}

export function hexToAscii(hexString) {
  return web3.utils.hexToAscii(hexString);
}

export function createSHA256Hash(message) {
  const hash = crypto.createHash('sha256').update(message).digest();
  return '0x' + Buffer.from(hash).toString('hex');
}

export function createRandomHexString(length) {
  return crypto.randomBytes(length).toString('hex');
}

export function isValidDid(did) {
  const didRegex = /^(DID|did):(SDI|sdi):0x[a-fA-F0-9]{40}$/;
  return did.match(didRegex);
}

export function didToAddress(did) {
  const didValue = did.split(':')[2];
  try {
    const publicKeyUintArray = base58btc.decode(didValue);
    const compressedPublicKey = Buffer.from(publicKeyUintArray).toString('hex');
    const decompressedBuffer = secp256k1.publicKeyConvert(
      Buffer.from(compressedPublicKey, 'hex'),
      false
    );
    const hash = createKeccakHash('keccak256')
      .update(Buffer.from(decompressedBuffer).slice(1))
      .digest();
    const address = toChecksumAddress(hash.slice(-20).toString('hex'));
    return address;
  } catch (e) {
    return didValue;
  }
}

export function hashWithKeccak(message) {
  const hash = createKeccakHash('keccak256')
    .update(Buffer.from(message.replace('0x', ''), 'hex'))
    .digest('hex');
  return hash;
}

export function formatSimpleDate(dateString) {
  if (!dateString) return null;
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);
  return `${day}/${month}/${year}`;
}

export function formatDateWithGMT(dateString) {
  // Get the current date
  var now = new Date(dateString);

  // Calculate local time in ISO format
  var tzo = -now.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      var norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };

  // Return the formatted local date with GMT offset
  return (
    now.getFullYear() +
    '-' +
    pad(now.getMonth() + 1) +
    '-' +
    pad(now.getDate()) +
    'T' +
    pad(now.getHours()) +
    ':' +
    pad(now.getMinutes()) +
    ':' +
    pad(now.getSeconds()) +
    '.' +
    String((now.getMilliseconds() / 1000).toFixed(3)).slice(2, 5) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  );
}

export function getLocalDateISOString(dateString) {
  // Get the current date
  var now = new Date(dateString);

  // Calculate local time in ISO format
  var tzo = -now.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      var norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };

  // Return the formatted local date with GMT offset
  return (
    now.getFullYear() +
    '-' +
    pad(now.getMonth() + 1) +
    '-' +
    pad(now.getDate()) +
    'T' +
    pad(now.getHours()) +
    ':' +
    pad(now.getMinutes()) +
    ':' +
    pad(now.getSeconds()) +
    '.' +
    String((now.getMilliseconds() / 1000).toFixed(3)).slice(2, 5) +
    'Z'
  );
}

export function toLocalISO(dateString) {
  const date = new Date(dateString);

  function pad(number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }

  var offset = -date.getTimezoneOffset(),
    sign = offset >= 0 ? '+' : '-',
    padHours = pad(Math.floor(Math.abs(offset) / 60)),
    padMinutes = pad(Math.abs(offset) % 60),
    localISO =
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds()) +
      sign +
      padHours +
      ':' +
      padMinutes;

  return localISO;
}

export function templateTypeToLabel(type, i18n, short) {
  switch (type) {
    case 'Basic':
      return short
        ? i18n.t('badges.certifiedShort')
        : i18n.t('badges.certified');
    case 'Community':
      return short
        ? i18n.t('badges.communityShort')
        : i18n.t('badges.community');
    case 'Participation':
      return short
        ? i18n.t('badges.participationShort')
        : i18n.t('badges.participation');
    case 'Ticket':
      return short ? i18n.t('badges.ticketShort') : i18n.t('badges.ticket');
    case 'Membership':
      return short
        ? i18n.t('badges.membershipShort')
        : i18n.t('badges.membership');
    case 'Role':
      return short ? i18n.t('badges.roleShort') : i18n.t('badges.role');
  }
}

function base64ToFile(base64String, filename) {
  const arr = base64String.split(',');

  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const base64String = event.target.result; // Remove the data URL prefix
      resolve(base64String);
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(file); // Use readAsDataURL to get a Base64 string
  });
}

async function getBase64FromImageUrl(url) {
  return fetch(url)
    .then((response) => {
      // Ensure the fetch was successful
      if (response.ok) return response.blob();
      throw new Error('Network response was not ok.');
    })
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

function showToastMessageError(text) {
  createToast(text, {
    position: 'bottom-center',
    hideProgressBar: true,
    timeout: 3000,
    transition: 'slide',
    toastBackgroundColor: '#ff4545',
  });
}

function showToastMessageSuccess(text) {
  createToast(text, {
    position: 'bottom-center',
    hideProgressBar: true,
    timeout: 3000,
    transition: 'slide',
    toastBackgroundColor: '#050931',
  });
}

export default {
  async install(app) {
    const utils = {
      asciiToHex,
      hexToAscii,
      createSHA256Hash,
      createRandomHexString,
      isValidDid,
      didToAddress,
      formatSimpleDate,
      formatDateWithGMT,
      getLocalDateISOString,
      toLocalISO,
      base64ToFile,
      fileToBase64,
      getBase64FromImageUrl,
      templateTypeToLabel,
      hashWithKeccak,
      showToastMessageError,
      showToastMessageSuccess,
    };
    app.provide('utils', utils);
  },
};
